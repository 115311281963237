<template>
  <b-table
    striped
    hover
    responsive
    small
    :fields="fields"
    :items="reportData"
  >
    <template #cell(id)="data">
      {{ data.item.id }}
    </template>
    <template #cell(brand)="data">
      <div class="text-primary">
        {{ data.item.brand }}
      </div>
      <div class="text-secondary">
        {{ data.item.department }}
      </div>
      <div class="text-warning">
        {{ data.item.location }}
      </div>
    </template>
    <template #cell(created)="data">
      {{ moment(data.item.created).format('ll') }}
      <div class="text-warning">
        <strong>Durum:</strong>
        {{ data.item.crm_status }}
      </div>
    </template>
    <template #cell(answer_duration)="data">
      <div>
        <span v-if="data.item.aduration.days">
          {{ data.item.aduration.days }} Gün
        </span>
        <span v-if="data.item.aduration.hours">
          {{ data.item.aduration.hours }} sa
        </span>
        <span v-if="data.item.aduration.minutes">
          {{ data.item.aduration.minutes }} dk
        </span>
        <span v-if="data.item.aduration.seconds">
          {{ data.item.aduration.seconds }} sn
        </span>
      </div>
      <div>
        <span
          v-if="data.item.ans_all_duration.days"
          class="text-muted font-small-1"
        >
          {{ data.item.ans_all_duration.days }} Gün
        </span>
        <span
          v-if="data.item.ans_all_duration.hours"
          class="text-muted font-small-1"
        >
          {{ data.item.ans_all_duration.hours }} sa
        </span>
        <span
          v-if="data.item.ans_all_duration.minutes"
          class="text-muted font-small-1"
        >
          {{ data.item.ans_all_duration.minutes }} dk
        </span>
        <span
          v-if="data.item.ans_all_duration.seconds"
          class="text-muted font-small-1"
        >
          {{ data.item.ans_all_duration.seconds }} sn
        </span>
      </div>
    </template>
    <template #cell(solution_duration)="data">
      <div>
        <span v-if="data.item.sduration.days">
          {{ data.item.sduration.days }} Gün
        </span>
        <span v-if="data.item.sduration.hours">
          {{ data.item.sduration.hours }} sa
        </span>
        <span v-if="data.item.sduration.minutes">
          {{ data.item.sduration.minutes }} dk
        </span>
        <span v-if="data.item.sduration.seconds">
          {{ data.item.sduration.seconds }} sn
        </span>
      </div>
      <div>
        <span
          v-if="data.item.sol_all_duration.days"
          class="text-muted font-small-1"
        >
          {{ data.item.sol_all_duration.days }} Gün
        </span>
        <span
          v-if="data.item.sol_all_duration.hours"
          class="text-muted font-small-1"
        >
          {{ data.item.sol_all_duration.hours }} sa
        </span>
        <span
          v-if="data.item.sol_all_duration.minutes"
          class="text-muted font-small-1"
        >
          {{ data.item.sol_all_duration.minutes }} dk
        </span>
        <span
          v-if="data.item.sol_all_duration.seconds"
          class="text-muted font-small-1"
        >
          {{ data.item.sol_all_duration.seconds }} sn
        </span>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BTable,
} from 'bootstrap-vue'

export default {
  name: 'DetailTable',
  components: {
    BTable,
  },
  props: {
    reportData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'id', label: 'NO', thClass: 'font-small-1', tdClass: 'font-small-1',
        },
        {
          key: 'brand', label: 'DEPARTMAN', thClass: 'font-small-1', tdClass: 'font-small-1',
        },
        {
          key: 'feedback_type', label: 'BAŞVURU TİPİ', thClass: 'font-small-1', tdClass: 'font-small-1',
        },
        {
          key: 'applysource', label: 'BAŞVURU KAYNAĞI', thClass: 'font-small-1', tdClass: 'font-small-1',
        },
        {
          key: 'created', label: 'BAŞVURU', thClass: 'font-small-1', tdClass: 'font-small-1',
        },
        {
          key: 'answer_duration', label: 'YANIT SÜRESİ', sortable: true, thClass: 'font-small-1', tdClass: 'font-small-1',
        },
        {
          key: 'solution_duration', label: 'ÇÖZÜM SÜRESİ', sortable: true, thClass: 'font-small-1', tdClass: 'font-small-1',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
