<template>
  <div>
    <data-filter
      :get-filter-data="getReport"
      :download-url="downloadURL"
    />
    <loading v-if="loadingReport === true" />
    <duration-table
      v-if="loadingReport === false"
      :report-data="reportData"
    />
  </div>
</template>

<script>
import DataFilter from '@/views/Reports/Crm/RequestDurations/DataFilter.vue'
import Loading from '@/views/Reports/Crm/RequestDurations/LoadingReport.vue'
import DurationTable from '@/views/Reports/Crm/RequestDurations/DurationTable.vue'

export default {
  name: 'RequestDurations',
  components: {
    DataFilter,
    Loading,
    DurationTable,
  },
  computed: {
    filterData() {
      return this.$store.getters['requestDurations/getFilterData']
    },
    loadingReport() {
      return this.$store.getters['requestDurations/getLoading']
    },
    reportData() {
      return this.$store.getters['requestDurations/getReport']
    },
    downloadURL() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_brand) {
        this.filterData.id_com_brand.forEach(e => {
          urlParams.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.filterData.id_com_department) {
        this.filterData.id_com_department.forEach(e => {
          urlParams.push(`id_com_department[]=${e}`)
        })
      }
      if (this.filterData.id_com_location) {
        this.filterData.id_com_location.forEach(e => {
          urlParams.push(`id_com_location[]=${e}`)
        })
      }
      if (this.filterData.id_com_feedback_type) {
        this.filterData.id_com_feedback_type.forEach(e => {
          urlParams.push(`id_com_feedback_type[]=${e}`)
        })
      }
      if (this.filterData.id_com_crm_applysource) {
        this.filterData.id_com_crm_applysource.forEach(e => {
          urlParams.push(`id_com_crm_applysource[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/crm/RequestDurations/pdf?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  methods: {
    getReport() {
      this.$store.dispatch('requestDurations/report', this.filterData)
    },
  },
}
</script>
