<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Marka"
          label-for="id_com_brand"
        >
          <v-select
            id="id_com_brand"
            v-model="filterData.id_com_brand"
            label="title"
            :options="brands"
            placeholder="Seçiniz"
            :close-on-select="false"
            :reduce="brand => brand.id"
            multiple
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Departman"
          label-for="id_com_department"
        >
          <v-select
            id="id_com_department"
            v-model="filterData.id_com_department"
            label="title"
            :options="departments"
            placeholder="Seçiniz"
            :close-on-select="false"
            :reduce="department => department.id"
            multiple
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Lokasyon"
          label-for="id_com_location"
        >
          <v-select
            id="id_com_location"
            v-model="filterData.id_com_location"
            label="title"
            :options="locations"
            placeholder="Seçiniz"
            :close-on-select="false"
            :reduce="location => location.id"
            multiple
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Başvuru Tipi"
          label-for="id_com_feedback_type"
        >
          <v-select
            id="id_com_feedback_type"
            v-model="filterData.id_com_feedback_type"
            label="title"
            :options="feedbackTypes"
            placeholder="Seçiniz"
            :close-on-select="false"
            :reduce="feedbackType => feedbackType.id"
            multiple
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Başvuru Kaynağı"
          label-for="id_com_crm_applysource"
        >
          <v-select
            id="id_com_crm_applysource"
            v-model="filterData.id_com_crm_applysource"
            label="title"
            :options="crmApplySources"
            placeholder="Seçiniz"
            :close-on-select="false"
            :reduce="crmApplySource => crmApplySource.id"
            multiple
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-datepicker
          v-model="filterData.sdate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-datepicker
          v-model="filterData.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-col>
      <b-col
        cols="12"
        class="mt-2 d-flex justify-content-between"
      >
        <b-button
          variant="primary"
          :disabled="!filterData.sdate || !filterData.edate"
          @click="getFilterData"
        >
          <FeatherIcon icon="FilterIcon" />
          Raporu Görüntüle
        </b-button>
        <div>
          <b-button
            variant="danger"
            :href="downloadUrl"
            target="_blank"
            class="mr-1"
            :disabled="!filterData.sdate || !filterData.edate"
          >
            <FeatherIcon icon="DownloadIcon" />
            Özet PDF İndir
          </b-button>
          <b-button
            variant="danger"
            :href="downloadUrl + '&detail=true'"
            target="_blank"
            class="mr-1"
            :disabled="!filterData.sdate || !filterData.edate"
          >
            <FeatherIcon icon="DownloadIcon" />
            Detaylı PDF İndir
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BButton, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterData',
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BFormDatepicker,
    BButton,
    BFormGroup,
  },
  props: {
    getFilterData: {
      type: Function,
      required: true,
    },
    downloadUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
    feedbackTypes() {
      return this.$store.getters['feedbackTypes/getFeedback_types']
    },
    crmApplySources() {
      return this.$store.getters['crmApplySources/getCrm_applysources']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    filterData() {
      return this.$store.getters['requestDurations/getFilterData']
    },
  },
  created() {
    this.getDepartments()
    this.getFeedbackTypes()
    this.getCrmApplySources()
    this.getBrands()
    this.getLocations()
  },
  methods: {
    getDepartments() {
      this.$store.dispatch('departments/departmentsList', {
        select: ['com_department.id AS id', 'com_department.title AS title'],
        where_in: [
          [
            'com_department.type', [1, 2],
          ],
        ],
      })
    },
    getFeedbackTypes() {
      this.$store.dispatch('feedbackTypes/feedback_typesList', {
        select: ['com_feedback_type.id AS id', 'com_feedback_type.title AS title'],
      })
    },
    getCrmApplySources() {
      this.$store.dispatch('crmApplySources/crm_applysourcesList', {
        select: ['com_crm_applysource.id AS id', 'com_crm_applysource.title AS title'],
      })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
      })
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: ['com_location.id AS id', 'com_location.title AS title'],
      })
    },
  },
}
</script>
