<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Başvuru Dönüş Süreleri</b-card-title>
    </b-card-header>
    <b-card-body class="text-center">
      <b-alert
        show
        variant="info"
      >
        <div class="alert-body">
          <FeatherIcon icon="InfoIcon" />
          <p>Rapor içeriğindeki ilk yanıt ve çözüm yanıtı süreleri örnek başvuru ile beraber nasıl hesaplanıyor?</p>
          <b-button
            variant="info"
            href="https://inll.app/Medusa2/media/guides/basvuru-suresi-hesaplama.pdf"
            target="_blank"
            pill
          >
            Görüntüle
          </b-button>
        </div>
      </b-alert>
    </b-card-body>
    <b-table-simple
      hover
      caption-top
      caption-bottom
      responsive
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th class="font-small-2 align-middle">
            DANIŞMAN
          </b-th>
          <b-th class="font-small-2 width-100 align-middle text-center">
            ADET
          </b-th>
          <b-th class="font-small-2 width-250 align-middle text-right">
            İLK YANIT ORTALAMASI
          </b-th>
          <b-th class="font-small-2 width-250 align-middle text-right">
            ÇÖZÜM ORTALAMASI
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(userAVG,key) in reportData">
          <b-tr :key="key">
            <b-td class="font-small-2 width-250 align-middle">
              <b-button
                variant="flat-success"
                class="btn-icon rounded-circle"
                @click="userAVG.detailToggle = ! userAVG.detailToggle"
              >
                <FeatherIcon
                  :icon="!userAVG.detailToggle? 'EyeIcon' : 'EyeOffIcon'"
                />
              </b-button>
              {{ userAVG.username }}
            </b-td>
            <b-td class="font-small-2 width-100 align-middle text-center">
              {{ userAVG.request_counts }}
            </b-td>
            <b-td class="font-small-2 width-250 align-middle text-right">
              <div>
                <span v-if="userAVG.answer_avg_times.days">
                  {{ userAVG.answer_avg_times.days }} Gün
                </span>
                <span v-if="userAVG.answer_avg_times.hours">
                  {{ userAVG.answer_avg_times.hours }} sa
                </span>
                <span v-if="userAVG.answer_avg_times.minutes">
                  {{ userAVG.answer_avg_times.minutes }} dk
                </span>
                <span v-if="userAVG.answer_avg_times.seconds">
                  {{ userAVG.answer_avg_times.seconds }} sn
                </span>
              </div>
              <div>
                <small
                  v-if="userAVG.answer_all_avg_times.days"
                  class="text-muted font-small-1"
                >
                  {{ userAVG.answer_all_avg_times.days }} Gün
                </small>
                <small
                  v-if="userAVG.answer_all_avg_times.hours"
                  class="text-muted font-small-1"
                >
                  {{ userAVG.answer_all_avg_times.hours }} sa
                </small>
                <small
                  v-if="userAVG.answer_all_avg_times.minutes"
                  class="text-muted font-small-1"
                >
                  {{ userAVG.answer_all_avg_times.minutes }} dk
                </small>
                <small
                  v-if="userAVG.answer_all_avg_times.seconds"
                  class="text-muted font-small-1"
                >
                  {{ userAVG.answer_all_avg_times.seconds }} sn
                </small>
              </div>
            </b-td>
            <b-td class="font-small-2 width-250 align-middle text-right">
              <div>
                <span v-if="userAVG.solution_avg_times.days">
                  {{ userAVG.solution_avg_times.days }} Gün
                </span>
                <span v-if="userAVG.solution_avg_times.hours">
                  {{ userAVG.solution_avg_times.hours }} sa
                </span>
                <span v-if="userAVG.solution_avg_times.minutes">
                  {{ userAVG.solution_avg_times.minutes }} dk
                </span>
                <span v-if="userAVG.solution_avg_times.seconds">
                  {{ userAVG.solution_avg_times.seconds }} sn
                </span>
              </div>
              <div>
                <div>
                  <small
                    v-if="userAVG.solution_all_avg_times.days"
                    class="text-muted font-small-1"
                  >
                    {{ userAVG.solution_all_avg_times.days }} Gün
                  </small>
                  <small
                    v-if="userAVG.solution_all_avg_times.hours"
                    class="text-muted font-small-1"
                  >
                    {{ userAVG.solution_all_avg_times.hours }} sa
                  </small>
                  <small
                    v-if="userAVG.solution_all_avg_times.minutes"
                    class="text-muted font-small-1"
                  >
                    {{ userAVG.solution_all_avg_times.minutes }} dk
                  </small>
                  <small
                    v-if="userAVG.solution_all_avg_times.seconds"
                    class="text-muted font-small-1"
                  >
                    {{ userAVG.solution_all_avg_times.seconds }} sn
                  </small>
                </div>
              </div>
            </b-td>
          </b-tr>
          <b-tr
            v-if="userAVG.detailToggle"
            :key="'detail_' + key"
          >
            <b-td
              colspan="4"
              class="m-0 p-0"
            >
              <detail-table :report-data="userAVG.requests" />
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BTableSimple,
  BTd,
  BTbody,
  BTh,
  BThead,
  BTr,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import DetailTable from '@/views/Reports/Crm/RequestDurations/DetailTable.vue'

export default {
  name: 'DurationTable',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTableSimple,
    BTd,
    BTbody,
    BTh,
    BThead,
    BTr,
    BButton,
    BAlert,
    DetailTable,
  },
  props: {
    reportData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'username', label: 'PERSONEL', thClass: 'font-small-2', tdClass: 'font-small-2',
        },
        {
          key: 'answer_avg', label: 'YANIT SÜRESİ', sortable: true, thClass: 'font-small-2', tdClass: 'font-small-2',
        },
        {
          key: 'solution_avg', label: 'ÇÖZÜM SÜRESİ', sortable: true, thClass: 'font-small-2', tdClass: 'font-small-2',
        },
      ],
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['requestDurations/getFilterData']
    },
    dataCount() {
      return this.$store.getters['requestDurations/getDataCount']
    },
  },
  methods: {
    getReport() {
      this.$store.dispatch('requestDurations/report', this.filterData)
    },
  },
}
</script>

<style scoped>

</style>
